<template>
  <b-container class="kt-container" fluid>
    <b-row>
      <b-col sm="12" class="mx-auto card border-secondary">
        <div class="card-body">
          <h2
            class="card-title text-center pb-3 font-weight-bold text-uppercase"
          >
            {{ id === "" ? $tc("ADVANCED_SETTINGS.INVOICING.NEW") : form.name }}
          </h2>

          <div
            class="accordion accordion-solid accordion-toggle-plus"
            id="accordion-form-invoicing"
          >
            <div v-if="usersAccess" class="card m-3">
              <div class="card-header">
                <div
                  class="card-title collapsed py-3 pr-10 font-weight-normal"
                  data-toggle="collapse"
                  data-target="#collapse_user"
                >
                  <span>{{
                    $tc("ADVANCED_SETTINGS.INVOICING.SECTION_USERS")
                  }}</span>
                </div>
              </div>

              <div
                id="collapse_user"
                class="collapse"
                data-parent="#accordion-form-invoicing"
              >
                <Users ref="user"></Users>
              </div>
            </div>

            <div class="card m-3">
              <div class="card-header">
                <div
                  class="card-title collapsed py-3 pr-10 font-weight-normal"
                  data-toggle="collapse"
                  data-target="#collapse_main"
                >
                  <span>{{
                    $tc("ADVANCED_SETTINGS.INVOICING.SECTION_INFOS")
                  }}</span>
                </div>
              </div>

              <div
                id="collapse_main"
                class="collapse"
                data-parent="#accordion-form-invoicing"
              >
                <div class="card-body">
                  <fieldset class="border p-2 px-4 mb-4">
                    <legend class="w-auto">
                      {{ $tc("ADVANCED_SETTINGS.INVOICING.FIELD_CONTACT") }}
                    </legend>

                    <div
                      v-if="hasAccess('contactFirstname', 'read')"
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.FIRST_NAME") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-input
                          v-model="form.contactFirstname"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.FIRST_NAME')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('contactFirstname', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('contactLastname', 'read')"
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.LAST_NAME") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-input
                          v-model="form.contactLastname"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.LAST_NAME')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('contactLastname', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('contactEmail', 'read')"
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.EMAIL") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-input
                          v-model="form.contactEmail"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.EMAIL')
                          "
                          class="form-control--grey"
                          :state="checkEmail"
                          :disabled="!hasAccess('contactEmail', 'write')"
                        />

                        <b-form-invalid-feedback :state="checkEmail">
                          {{
                            $tc(
                              "ADVANCED_SETTINGS.INVOICING.FORM_ERROR.WRONG_FORMAT"
                            )
                          }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('phone', 'read')"
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.PHONE") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-input
                          v-model="form.phone"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.PHONE')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('phone', 'write')"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="border p-2 px-4 mb-4">
                    <legend class="w-auto">
                      {{ $tc("ADVANCED_SETTINGS.INVOICING.FIELD_ADDRESS") }}
                    </legend>

                    <div
                      v-if="hasAccess('address', 'read')"
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.ADDRESS") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-input
                          v-model="form.address"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.ADDRESS')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('address', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('zipCode', 'read')"
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.ZIP_CODE") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-input
                          v-model="form.zipCode"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.ZIP_CODE')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('zipCode', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('city', 'read')"
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.CITY") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-input
                          v-model="form.city"
                          :placeholder="$tc('ADVANCED_SETTINGS.INVOICING.CITY')"
                          class="form-control--grey"
                          :disabled="!hasAccess('city', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.COUNTRY") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <Combobox
                          :items="countries"
                          :value="countryObject.nameEn"
                          :itemSelected="countryObject"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.COUNTRY')
                          "
                          fieldName="nameEn"
                          :errorMessage="
                            $tc(
                              'ADVANCED_SETTINGS.INVOICING.FORM_ERROR.NOT_EMPTY'
                            )
                          "
                          @selectElement="selectCountry"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="border p-2 px-4 mb-4">
                    <legend class="w-auto">
                      {{ $tc("ADVANCED_SETTINGS.INVOICING.FIELD_INFORMATION") }}
                    </legend>

                    <div
                      v-if="hasAccess('vat', 'read')"
                      class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.VAT_NUMBER") }}
                      </label>

                      <div class="d-flex flex-column w-25">
                        <b-form-input
                          v-model="form.vat"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.VAT_NUMBER')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('vat', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('rcs', 'read')"
                      class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.RCS") }}
                      </label>

                      <div class="d-flex flex-column w-25">
                        <b-form-input
                          v-model="form.rcs"
                          :placeholder="$tc('ADVANCED_SETTINGS.INVOICING.RCS')"
                          class="form-control--grey"
                          :disabled="!hasAccess('rcs', 'write')"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="border p-2 px-4 mb-4">
                    <legend class="w-auto">
                      {{ $tc("ADVANCED_SETTINGS.INVOICING.INVOICE") }}
                    </legend>

                    <div
                      v-if="hasAccess('vatPercent', 'read')"
                      class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.VAT_PERCENT") }}
                      </label>

                      <div class="d-flex flex-column w-25">
                        <b-form-input
                          v-model="form.vatPercent"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.VAT_PERCENT')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('vatPercent', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('currency', 'read')"
                      class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.CURRENCY") }}
                      </label>

                      <div class="d-flex flex-column w-25">
                        <b-form-input
                          v-model="form.currency"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.INVOICING.CURRENCY')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('currency', 'write')"
                        />
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('autoInvoice', 'read')"
                      class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.INVOICING.AUTOINVOICE") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-checkbox
                          v-model="form.autoInvoice"
                          name="check-button"
                          switch
                          :disabled="!hasAccess('autoInvoice', 'write')"
                        >
                        </b-form-checkbox>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset
                    v-if="hasAccess('hostingAccess', 'read')"
                    class="border p-2 px-4 mb-4"
                  >
                    <legend class="w-auto">
                      {{ $tc("ADVANCED_SETTINGS.HOSTING.TITLE") }}
                    </legend>

                    <div
                      v-if="hasAccess('hostingAccess', 'read')"
                      class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.HOSTING.ACCESS") }}
                      </label>

                      <div class="d-flex flex-column w-75">
                        <b-form-checkbox
                          v-model="form.hostingAccess"
                          name="check-button"
                          switch
                          :disabled="!hasAccess('hostingAccess', 'write')"
                        >
                        </b-form-checkbox>
                      </div>
                    </div>

                    <div
                      v-if="hasAccess('acpmGroupId', 'read')"
                      class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                    >
                      <label class="w-25 mr-2"
                        >{{ $tc("ADVANCED_SETTINGS.HOSTING.ACPM_BRAND_ID") }}
                      </label>

                      <div class="d-flex flex-column w-25">
                        <b-form-input
                          v-model="form.acpmGroupId"
                          :placeholder="
                            $tc('ADVANCED_SETTINGS.HOSTING.ACPM_BRAND_ID')
                          "
                          class="form-control--grey"
                          :disabled="!hasAccess('acpmGroupId', 'write')"
                          :state="checkAcpmGroupId"
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="card m-3">
              <div class="card-header">
                <div
                  class="card-title collapsed py-3 pr-10 font-weight-normal"
                  data-toggle="collapse"
                  data-target="#collapse_settings"
                >
                  <span>{{
                    $tc("ADVANCED_SETTINGS.INVOICING.SECTION_ADMIN")
                  }}</span>
                </div>
              </div>

              <div
                id="collapse_settings"
                class="collapse"
                data-parent="#accordion-form-invoicing"
              >
                <div class="card-body">
                  <div
                    v-if="hasAccess('enabled', 'read')"
                    class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                  >
                    <label class="w-25 mr-2"
                      >{{ $tc("ADVANCED_SETTINGS.INVOICING.ENABLED") }}
                    </label>

                    <div class="d-flex flex-column w-75">
                      <b-form-checkbox
                        v-model="form.enabled"
                        name="check-button"
                        switch
                        :disabled="!hasAccess('enabled', 'write')"
                      >
                      </b-form-checkbox>
                    </div>
                  </div>

                  <div
                    v-if="hasAccess('name', 'read')"
                    class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                  >
                    <label class="w-25 mr-2"
                      >{{ $tc("ADVANCED_SETTINGS.INVOICING.NAME")
                      }}<span class="text-danger">*</span>
                    </label>

                    <div class="d-flex flex-column w-75">
                      <b-form-input
                        v-model="form.name"
                        :placeholder="$tc('ADVANCED_SETTINGS.INVOICING.NAME')"
                        class="form-control--grey"
                        :state="checkCompany"
                        :disabled="!hasAccess('name', 'write')"
                      />

                      <b-form-invalid-feedback :state="checkCompany">
                        {{
                          $tc(
                            "ADVANCED_SETTINGS.INVOICING.FORM_ERROR.NOT_EMPTY"
                          )
                        }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div
                    v-if="hasAccess('techFees', 'read')"
                    class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                  >
                    <label class="w-25 mr-2"
                      >{{ $tc("ADVANCED_SETTINGS.INVOICING.TECH_FEES") }}
                    </label>

                    <div class="d-flex flex-column w-25">
                      <b-form-input
                        v-model="form.techFees"
                        type="number"
                        :placeholder="
                          $tc('ADVANCED_SETTINGS.INVOICING.TECH_FEES')
                        "
                        class="form-control--grey"
                        :disabled="!hasAccess('techFees', 'write')"
                      />
                    </div>
                  </div>

                  <div
                    v-if="hasAccess('raveshare', 'read')"
                    class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                  >
                    <label class="w-25 mr-2"
                      >{{ $tc("ADVANCED_SETTINGS.INVOICING.REVENUE_SHARE") }}
                    </label>

                    <div class="d-flex flex-column w-25">
                      <b-form-input
                        v-model="form.raveshare"
                        type="number"
                        :placeholder="
                          $tc('ADVANCED_SETTINGS.INVOICING.REVENUE_SHARE')
                        "
                        class="form-control--grey"
                        :disabled="!hasAccess('raveshare', 'write')"
                      />
                    </div>
                  </div>

                  <div
                    v-if="hasAccess('categories', 'read')"
                    class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                  >
                    <label class="w-25 mr-2"
                      >{{ $tc("ADVANCED_SETTINGS.INVOICING.COMPANY_TYPE") }}
                    </label>

                    <div class="d-flex flex-column w-25">
                      <SelectMultiple
                        :label="$tc('ADVANCED_SETTINGS.INVOICING.COMPANY_TYPE')"
                        :options="categories"
                        :items="form.categories ?? []"
                        @change="selectCategory"
                        ref="categories"
                        :disabled="!hasAccess('categories', 'write')"
                      ></SelectMultiple>
                    </div>
                  </div>

                  <div
                    v-if="hasAccess('passwordExpirationDays', 'read')"
                    class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
                  >
                    <label class="w-25 mr-2"
                      >{{
                        $tc(
                          "ADVANCED_SETTINGS.INVOICING.PASSWORD_EXPIRATION_DAYS"
                        )
                      }}
                    </label>

                    <div class="d-flex flex-column w-25">
                      <b-form-input
                        v-model="form.passwordExpirationDays"
                        type="number"
                        :placeholder="
                          $tc(
                            'ADVANCED_SETTINGS.INVOICING.PASSWORD_EXPIRATION_DAYS'
                          )
                        "
                        class="form-control--grey"
                        :disabled="
                          !hasAccess('passwordExpirationDays', 'write')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-auto pb-3">
          <button
            type="submit"
            class="btn mx-1 btn-primary"
            :disabled="isLoadingSubmit"
            @click="submit"
          >
            <template v-if="!isLoadingSubmit"> Submit </template>
            <b-spinner v-if="isLoadingSubmit" label="Spinning"></b-spinner>
          </button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Combobox from "@/components/settings/Combobox";
import { getCountries } from "@/api/countries/getters";
import {
  createCompanyV2,
  getCompanyV2,
  updateCompanyV2
} from "@/api/companies/getters";
import SelectMultiple from "@/components/SelectMultiple";
import auth from "@/store/auth.module";
import { FORM_COMPANY } from "@/common/config/acl/company/abac";
import {
  getAttributesRead,
  getAttributesWrite,
  hasAttributeAccess
} from "@/common/config/acl";
import Users from "@/views/pages/company/Users";
import { ROLES } from "@/common/config/acl/roles";

export default {
  components: {
    Users,
    SelectMultiple,
    Combobox
  },
  data() {
    return {
      user: auth.state.user,
      id: "",
      form: getAttributesRead(FORM_COMPANY, auth.state.user.roles),
      categories: ["Publisher", "Advertiser"],
      countryObject: { nameFr: "", nameEn: "" },
      countries: [],
      isLoadingCountries: false,
      isCanCheck: false,
      isLoadingSubmit: false
    };
  },

  mounted() {
    this.resetData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Company Create" }]);

    this.loadCountries();

    if (undefined !== this.$route.params.id) {
      this.id = this.$route.params.id;
    }

    if ("" !== this.id) {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Company Update" }]);
      this.load();
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: "isAdmin"
    }),

    usersAccess() {
      return [
        ROLES.BBB_ADMIN,
        ROLES.BBB_MANAGER_PUBLISHER,
        ROLES.BBB_MANAGER_ADVERTISER
      ].filter(x => this.user.roles.includes(x)).length;
    },

    checkEmail() {
      const value = this.form.contactEmail || "";
      const regExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$|^$/;
      return this.isCanCheck ? regExp.test(value) : null;
    },

    checkCompany() {
      return this.isCanCheck ? this.form.name.length !== 0 : null;
    },

    checkAcpmGroupId() {
      const value = this.form.acpmGroupId || "";
      const regExp = /^[a-z0-9]{17,20}$|^$/;
      return this.isCanCheck ? regExp.test(value) : null;
    },

    hasErrors() {
      return !this.checkEmail || !this.checkCompany || !this.checkAcpmGroupId;
    }
  },
  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_COMPANY,
        this.user.roles,
        field,
        accessType
      );
    },

    async loadCountries() {
      this.isLoadingCountries = true;
      try {
        this.countries = await getCountries(this.axios);
      } catch (error) {
        console.error("---ERROR-LOAD-COUNTRIES");
        console.error(error);
      }
      this.isLoadingCountries = false;
    },

    selectCountry(item) {
      this.form.country = item.id;
      this.countryObject = item;
    },

    selectCategory() {
      this.form.categories = this.$refs.categories.selectedItems;
    },

    resetData() {
      this.form = getAttributesRead(FORM_COMPANY, this.user.roles);
    },

    async load() {
      this.resetData();
      try {
        if (this.id) {
          const data = await getCompanyV2(this.id, this.axios);
          this.form = getAttributesRead(FORM_COMPANY, this.user.roles, data);

          if (data.country !== null) {
            this.form.country = data.country.id;
            this.countryObject = data.country;
          }
        }
      } catch (error) {
        console.error("---ERROR-LOAD-COUNTRIES");
        console.error(error);
      }
    },

    async submit() {
      this.isCanCheck = true;
      this.isLoadingSubmit = true;
      let message = "";
      let variant = "success";

      if (!this.hasErrors) {
        try {
          const payload = getAttributesWrite(
            this.form,
            FORM_COMPANY,
            this.user.roles
          );

          if (this.id) {
            await updateCompanyV2(this.axios, this.id, payload);
            message = this.$tc(
              "ADVANCED_SETTINGS.INVOICING.TOAST.SUCCESS.MESSAGE_UPDATE"
            );
          } else {
            await createCompanyV2(this.axios, payload);
            message = this.$tc(
              "ADVANCED_SETTINGS.INVOICING.TOAST.SUCCESS.MESSAGE_CREATE"
            );
          }

          if (this.isAdmin) {
            await this.$router.push({ name: "admin_list_companies" });
          }
        } catch (error) {
          console.error("---ERROR-INVOICING---");
          console.error(error);

          message = this.$tc(
            "ADVANCED_SETTINGS.INVOICING.TOAST.ERROR.MESSAGE_API"
          );
          variant = "danger";
        }
      } else {
        message = this.$tc("ADVANCED_SETTINGS.INVOICING.TOAST.ERROR.MESSAGE");
        variant = "danger";
      }

      this.flashMessage(message, variant);
      this.isLoadingSubmit = false;
    },

    flashMessage(message, variant = "success") {
      //TODO: default value not working??
      this.showToast({
        title: this.$tc("ADVANCED_SETTINGS.INVOICING.TOAST.TITLE"),
        message: message,
        variant: variant
      });
    },

    showToast({ title, variant, message }) {
      const h = this.$createElement;
      const id = `toaster-message`;
      const $contentToaster = h("span", message);

      this.$bvToast.toast([$contentToaster], {
        id: id,
        title: title,
        variant: variant,
        noCloseButton: false
      });
    }
  }
};
</script>
