import { ROLES } from "@/common/config/acl/roles";

export const FORM_COMPANY = {
  id: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  externalId: {
    defaultValue: "",
    read: [],
    write: []
  },
  enabled: {
    defaultValue: true,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  name: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  contactFirstname: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  contactLastname: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  contactEmail: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  phone: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  address: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  country: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  zipCode: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  city: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  vat: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  rcs: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  techFees: {
    defaultValue: 0,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  raveshare: {
    defaultValue: 0,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  passwordExpirationDays: {
    defaultValue: 90,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  categories: {
    defaultValue: [],
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  hostingAccess: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  },
  acpmGroupId: {
    defaultValue: "",
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  },
  vatPercent: {
    defaultValue: 0.0,
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  },
  autoInvoice: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  },
  currency: {
    defaultValue: "€",
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN]
  }
};
